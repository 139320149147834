.page-container {
  position: relative;
  padding-bottom: 255px;
}

.wrapper {
  max-width: 690px;
}

.header-wrapper {
  max-width: 1190px;
}

.button {
  background-color: #ffa834;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px;
  min-width: 240px;
  font-size: small;
}

.button:disabled {
  background-color: #999999;
}

.link {
  color: #f6b326;
  text-decoration: none;
}

.link:hover {
  color: #f41929;
}

.banner {
  display: block;
  margin: auto;
}

.header-img {
  background-color: #fff;
}

.img-fluid {
  display: inline-block;
  vertical-align: middle;
}

.footer {
  background-color: #999999;
  position: absolute;
  padding: 25px 0px 50px 0px;
  width: 100%;
  bottom: 0px;
}

.form-control {
  border: 1px solid black;
  border-radius: 0px;
}

.footer-list {
  list-style: none;
  font-size: small;
}

.header {
  background-color: #f6b326;
}